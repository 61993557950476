import Api from '@/services/Index';

const storeLeaveSettingForm = async (formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post('/settings/leave/of/absence', formData);
}

const getLeaveSettingForm = async () => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/settings/leave/of/absence');
}

const getSemesterSettings = async () => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/settings/semester');
}

const storeSemesterSettings = async (formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post('/settings/semester', formData);
}

const getCourseSlotLogs = async (config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/logs/course-slot-logs', config);
}

export default {
    storeLeaveSettingForm,
    getLeaveSettingForm,
    getSemesterSettings,
    storeSemesterSettings,
    getCourseSlotLogs
}
